import {merge} from 'lodash';

const setInitialAdvanceRecordsData = (state, payload = {}) => {
  const {advance_records} = payload;

  if (advance_records) {
    let setTripCode = {};
    let updateData = state[advance_records.trip_code]?.map((item) => {
      if (item.id === advance_records.id) {
        return {
          ...item,
          ...advance_records,
        };
      } else {
        return item;
      }
    });

    setTripCode[advance_records.trip_code] = updateData;
    let data = advance_records.trip_code ? setTripCode : advance_records;
    const oldRecords = {...state};
    const newRecords = {...data};
    // const newRecords = {...advance_records};

    // const updatedState = merge(oldRecords, newRecords);
    const updatedState = {...oldRecords, ...newRecords};
    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitialAdvanceRecordsData(state, payload);
    }
  }
};

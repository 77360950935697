import {Auth} from '../../helpers/Urls/index';
import {PATH, REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';
import * as _ from 'lodash';
import history from '../../helpers/network/history';

const initialState = {
  authenticated: false,
  loading: false,
  isError: false,
  error: '',
  authenticatedUser: null,
  userEntity: '',
  token: null,
  initialDataLoading: false,
};

const SIGNING = 'SIGNING';
const SIGNING_SUCCESSFUL = 'SIGNING_SUCCESSFUL';
const SIGNING_FAILED = 'SIGNING_FAILED';
const RESEND_OTP = 'RESEND_OTP';
const RESEND_OTP_COMPLETED = 'RESEND_OTP_COMPLETED';
const RESEND_OTP_FAILED = 'RESEND_OTP_FAILED';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';
export const FETCH_USER_DETAILS_COMPLETED = 'FETCH_USER_DETAILS_COMPLETED';
export const FETCH_USER_DETAILS_FAILED = 'FETCH_USER_DETAILS_FAILED';

export const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA';
export const FETCH_INITIAL_DATA_COMPLETED = 'FETCH_INITIAL_DATA_COMPLETED';
export const FETCH_INITIAL_DATA_FAILED = 'FETCH_INITIAL_DATA_FAILED';

export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SAVE_PASSWORD_COMPLETED = 'SAVE_PASSWORD_COMPLETED';
export const SAVE_PASSWORD_FAILED = 'SAVE_PASSWORD_FAILED';

export const GENERATE_OTP = 'GENERATE_OTP';
export const GENERATE_OTP_COMPLETED = 'GENERATE_OTP_COMPLETED';
export const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_COMPLETED = 'SET_NEW_PASSWORD_COMPLETED';
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED';

export const CLEAR_ERROR = 'CLEAR_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

const auth = JSON.parse(localStorage.getItem('auth'));

const newState = {
  token: auth?.token || null,
  authenticatedUser: auth || null,
  authenticated: !!auth?.token,
};
const initialisedState = {
  ...initialState,
  ...newState,
};

export const signIn = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SIGNING});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.signInURL(),
      });

      const {data = {}, status = false, message = ''} = response;
      if (status && !_.isEmpty(data)) {
        dispatch({
          type: SIGNING_SUCCESSFUL,
          payload: response,
        });
      } else {
        dispatch({
          type: SIGNING_FAILED,
          payload: {
            error: Array.isArray(message) ? message[0] : message,
          },
        });
      }
    } catch (err) {
      console.log('error--2', err);
      dispatch({
        type: SIGNING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const resendOTP = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RESEND_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.resendOtpURL(),
      });
      const {data = {}, status = false, message = ''} = response;
      if (status) {
        dispatch({
          type: RESEND_OTP_COMPLETED,
          payload: response,
        });
      } else {
        dispatch({
          type: RESEND_OTP_FAILED,
          payload: {
            error: message,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: RESEND_OTP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchUserDetails = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_USER_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.fetchUserDetailsURL(),
      });

      const {data = {}, status = false, message = ''} = response;
      console.log('response ----', response);
      if (status) {
        dispatch({
          type: FETCH_USER_DETAILS_COMPLETED,
          payload: response,
        });
      } else {
        dispatch({
          type: FETCH_USER_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SIGNING_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchInitialData = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_INITIAL_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Auth.fetchInitialDataURL(),
      });
      const {data = {}, status = false, message = ''} = response;
      console.log('response ----', response);
      if (status) {
        dispatch({
          type: FETCH_INITIAL_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        dispatch({
          type: FETCH_INITIAL_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_INITIAL_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const generateOtp = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GENERATE_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.generateOtpURL(),
      });

      const {data = {}, status = false, message = ''} = response;
      console.log('response ----', response);
      if (status) {
        dispatch({
          type: GENERATE_OTP_COMPLETED,
          payload: response,
        });
      } else {
        dispatch({
          type: GENERATE_OTP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GENERATE_OTP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const savePassword = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_PASSWORD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.savePasswordURL(),
      });

      const {data = {}, status = false, message = ''} = response;
      console.log('response ----', response);
      if (status) {
        dispatch({
          type: SAVE_PASSWORD_COMPLETED,
          payload: response,
        });
      } else {
        dispatch({
          type: SAVE_PASSWORD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_PASSWORD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const signOut = () => {
  return async (dispatch) => {
    let response = {};
    try {
      console.log('sign out called----');
      dispatch({type: LOGOUT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Auth.signOutURL(),
        data: null,
      });
      console.log('sign out called----2');
      const {status = false, message = ''} = response;
      if (status) {
        dispatch({type: LOGOUT_SUCCESSFUL, payload: response});
        history.push(PATH.SIGN_IN); // Redirect to login page
      } else {
        dispatch({
          type: LOGOUT_FAILED,
          payload: {error: Array.isArray(message) ? message[0] : message},
        });
      }
    } catch (err) {
      dispatch({type: LOGOUT_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

export const changePassword = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SET_NEW_PASSWORD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: Auth.fetchChangePasswordURL(),
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: SET_NEW_PASSWORD_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SET_NEW_PASSWORD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SET_NEW_PASSWORD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = initialisedState, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case SIGNING:
    case SAVE_PASSWORD:
    case SET_NEW_PASSWORD:
    case GENERATE_OTP:
      return {
        ...state,
        loading: true,
        error: '',
        isError: false,
      };
    case SIGNING_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        error: payload.error || '',
        authenticated: false,
        authenticatedUser: null,
      };
    case SIGNING_SUCCESSFUL:
      localStorage.setItem('auth', JSON.stringify(payload.data));
      return {
        ...state,
        loading: false,
        isError: false,
        error: '',
        authenticated: true,
        authenticatedUser: payload.data || null,
        userEntity: payload.entity || '',
        token: payload.data.token || null,
      };
    // case FETCH_INITIAL_DATA_COMPLETED:
    //   // localStorage.setItem('auth', JSON.stringify(payload.auth));
    //   return {
    //     ...state,
    //     authenticatedUser: payload?.auth?.authenticatedUser || {},
    //   };
    case SAVE_PASSWORD_COMPLETED:
    case GENERATE_OTP_COMPLETED:
      return {
        ...state,
        loading: false,
        isError: false,
        error: '',
      };
    case SAVE_PASSWORD_FAILED:
    case SET_NEW_PASSWORD_FAILED:
    case GENERATE_OTP_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
        error: payload.error || '',
      };
    case LOGOUT_SUCCESSFUL:
      localStorage.clear();
      return {authenticated: false, authenticatedUser: null};
    case SET_NEW_PASSWORD_COMPLETED:
      const auth = localStorage.getItem('auth');
      const authParsed = JSON.parse(auth);
      authParsed.has_changed_password = true;
      localStorage.setItem('auth', JSON.stringify(authParsed));
      return {
        ...state,
        loading: false,
        isError: false,
        error: '',
        has_changed_password: true,
      };
    case FETCH_INITIAL_DATA:
      return {...state, initialDataLoading: true};
    case FETCH_INITIAL_DATA_COMPLETED:
      if (payload.auth.authenticatedUser) {
        const {auth: {authenticatedUser = {}} = {}} = payload;
        const {rights, role, username} = authenticatedUser;
        const prevAuth = JSON.parse(localStorage.getItem('auth'));
        prevAuth.rights = rights;
        prevAuth.role = role;
        prevAuth.username = username;
        localStorage.setItem('auth', JSON.stringify(prevAuth));
        return {
          ...state,
          initialDataLoading: false,
          authenticatedUser: authenticatedUser,
        };
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: '',
        isError: false,
      };
    default:
      return state;
  }
};

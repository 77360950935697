import {REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';

const INITIAL_STATE = {
  tableLoading: false,
  happayTableData: [],
};

export const openDrawerData = (id) => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'LOAD_DRAWER_DATA'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/finance/card-history/' + id,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'LOAD_DRAWER_DATA_COMPLETD', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'FAILED_DATA',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'FAILED_DATA',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getHappayData = () => {
  return async (dispatch) => {
    let response = {};
    dispatch({type: 'LOAD_HAPPAY_DATA'});

    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/finance/happay-card-history',
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'LOAD_HAPPAY_DATA_COMPLETD', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'LOAD_HAPPAY_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'LOAD_HAPPAY_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Reducers
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case 'LOAD_HAPPAY_DATA':
      return {
        ...state,
        tableLoading: true,
      };

    case 'LOAD_DRAWER_DATA':
      return {
        ...state,
        drawerDataLoad: true,
      };

    case 'LOAD_DRAWER_DATA_COMPLETD':
      return {
        ...state,
        drawerDataLoad: false,
        drawerTableData: payload,
      };

    case 'LOAD_HAPPAY_DATA_COMPLETD':
      return {
        ...state,
        happayTableData: payload,
        tableLoading: false,
      };
    case 'LOAD_HAPPAY_DATA_FAILED':
      return {
        ...state,
        tableLoading: false,
      };

    case 'FAILED_DATA':
      return {
        ...state,
        tableLoading: false,
        drawerDataLoad: false,
      };

    default:
      return {
        ...state,
      };
  }
};

import React from 'react';
import ReactDOM from 'react-dom';
import './Styles/index.less';
import App from './App';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
// const url = `${process.env.PUBLIC_URL}/OneSignalSDKWorker.js`;
// navigator.serviceWorker.register('/OneSignalSDKWorker.js');
// navigator.serviceWorker.register('/OneSignalSDKUpdaterWorker.js');

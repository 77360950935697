import {doRequest} from '../../helpers/network';
import {Annexure, Cost} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';
import {
  DELETE_COST_TRIPS_ANNEXURE,
  DELETE_COST_TRIPS_ANNEXURE_COMPLETED,
  DELETE_COST_TRIPS_ANNEXURE_FAILED,
  COST_OTHERS_HEADER_EDIT,
  COST_OTHERS_HEADER_EDIT_COMPLETED,
  COST_OTHERS_HEADER_EDIT_FAILED,
} from '../../modules/costAnnexures';

export const SEARCH_ANNEXURES_DETAILS = 'SEARCH_ANNEXURES_DETAILS';
export const SEARCH_ANNEXURES_DETAILS_COMPLETED =
  'SEARCH_ANNEXURES_DETAILS_COMPLETED';
export const SEARCH_ANNEXURES_DETAILS_FAILED =
  'SEARCH_ANNEXURES_DETAILS_FAILED';

export const GET_SEARCH_ANNEXURES_DATA = 'GET_SEARCH_ANNEXURES_DATA';
export const GET_SEARCH_ANNEXURES_DATA_COMPLETED =
  'GET_SEARCH_ANNEXURES_DATA_COMPLETED';
export const GET_SEARCH_ANNEXURES_DATA_FAILED =
  'GET_SEARCH_ANNEXURES_DATA_FAILED';

export const GET_ANNEXURES_DATA = 'GET_ANNEXURES_DATA';
export const GET_ANNEXURES_DATA_COMPLETED = 'GET_ANNEXURES_DATA_COMPLETED';
export const GET_ANNEXURES_DATA_FAILED = 'GET_ANNEXURES_DATA_FAILED';

export const PRINT_ANNEXURE = 'PRINT_ANNEXURE';
export const PRINT_ANNEXURE_COMPLETED = 'PRINT_ANNEXURE_COMPLETED';
export const PRINT_ANNEXURE_FAILED = 'PRINT_ANNEXURE_FAILED';

export const COMMENT_ANNEXURE = 'COMMENT_ANNEXURE';
export const COMMENT_ANNEXURE_COMPLETED = 'COMMENT_ANNEXURE_COMPLETED';
export const COMMENT_ANNEXURE_FAILED = 'COMMENT_ANNEXURE_FAILED';

export const OTHERS_HEADER_EDIT = 'OTHERS_HEADER_EDIT';
export const OTHERS_HEADER_EDIT_COMPLETED = 'OTHERS_HEADER_EDIT_COMPLETED';
export const OTHERS_HEADER_EDIT_FAILED = 'OTHERS_HEADER_EDIT_FAILED';

export const DELETE_TRIPS_ANNEXURE = 'DELETE_TRIPS_ANNEXURE';
export const DELETE_TRIPS_ANNEXURE_COMPLETED =
  'DELETE_TRIPS_ANNEXURE_COMPLETED';
export const DELETE_TRIPS_ANNEXURE_FAILED = 'DELETE_TRIPS_ANNEXURE_FAILED';

export const APPROVE_ANNEXURE = 'APPROVE_ANNEXURE';
export const APPROVE_ANNEXURE_COMPLETED = 'APPROVE_ANNEXURE_COMPLETED';
export const APPROVE_ANNEXURE_FAILED = 'APPROVE_ANNEXURE_FAILED';

export const REJECT_ANNEXURE = 'REJECT_ANNEXURE';
export const REJECT_ANNEXURE_COMPLETED = 'REJECT_ANNEXURE_COMPLETED';
export const REJECT_ANNEXURE_FAILED = 'REJECT_ANNEXURE_FAILED';

export const EDIT_ANNEXURE_DIESEL_DATE = 'EDIT_ANNEXURE_DIESEL_DATE';
export const EDIT_ANNEXURE_DIESEL_DATE_COMPLETED =
  'EDIT_ANNEXURE_DIESEL_DATE_COMPLETED';
export const EDIT_ANNEXURE_DIESEL_DATE_FAILED =
  'EDIT_ANNEXURE_DIESEL_DATE_FAILED';

export const EDIT_ANNEXURE_TRIP_REVENUE = 'EDIT_ANNEXURE_TRIP_REVENUE';
export const EDIT_ANNEXURE_TRIP_REVENUE_COMPLETED =
  'EDIT_ANNEXURE_TRIP_REVENUE_COMPLETED';
export const EDIT_ANNEXURE_TRIP_REVENUE_FAILED =
  'EDIT_ANNEXURE_TRIP_REVENUE_FAILED';

export const EDIT_COST_ANNEXURE_TRIP_REVENUE =
  'EDIT_COST_ANNEXURE_TRIP_REVENUE';
export const EDIT_COST_ANNEXURE_TRIP_REVENUE_COMPLETED =
  'EDIT_COST_ANNEXURE_TRIP_REVENUE_COMPLETED';
export const EDIT_COST_ANNEXURE_TRIP_REVENUE_FAILED =
  'EDIT_COST_ANNEXURE_TRIP_REVENUE_FAILED';

export const ANNEXURE_UPLOAD_BILL = 'ANNEXURE_UPLOAD_BILL';
export const ANNEXURE_UPLOAD_BILL_COMPLETED = 'ANNEXURE_UPLOAD_BILL_COMPLETED';
export const ANNEXURE_UPLOAD_BILL_FAILED = 'ANNEXURE_UPLOAD_BILL_FAILED';

export const SAVE_BILL_DETAILS = 'SAVE_BILL_DETAILS';
export const SAVE_BILL_DETAILS_COMPLETED = 'SAVE_BILL_DETAILS_COMPLETED';
export const SAVE_BILL_DETAILS_FAILED = 'SAVE_BILL_DETAILS_FAILED';

export const SEND_FOR_APPROVAL = 'SEND_FOR_APPROVAL';
export const SEND_FOR_APPROVAL_COMPLETED = 'SEND_FOR_APPROVAL_COMPLETED';
export const SEND_FOR_APPROVAL_FAILED = 'SEND_FOR_APPROVAL_FAILED';

export const DELETE_ANNEXURES = 'DELETE_ANNEXURES';
export const DELETE_ANNEXURES_COMPLETED = 'DELETE_ANNEXURES_COMPLETED';
export const DELETE_ANNEXURES_FAILED = 'DELETE_ANNEXURES_FAILED';

export const CREATE_NEXT_ANNEXURE = 'CREATE_NEXT_ANNEXURE';
export const CREATE_NEXT_ANNEXURE_COMPLETED = 'CREATE_NEXT_ANNEXURE_COMPLETED';
export const CREATE_NEXT_ANNEXURE_FAILED = 'CREATE_NEXT_ANNEXURE_FAILED';

const CLEAR_SEARCH_ANNEXURES_DATA = 'CLEAR_SEARCH_ANNEXURES_DATA';
const RESET_PAGE = 'RESET_PAGE';
const SET_TRIP_REJECT_DATA = 'SET_TRIP_REJECT_DATA';

const initialState = {
  isFetching: false,
  error: false,
  hasError: false,
  resetPage: false,
  annexuresData: {},
  requestingCommentAnnex: false,
  requestingNextAnnex: false,
  uploadingRevBill: false,
  savingBillDetails: false,
  annexureInfo: {},
  currentTripRejectData: {},
  deletingAnnex: false,
  isRequestingAnnexure: false,
  printingAnnex: false,
  updatingRev: false,
  updatingCostRev: false,
  updatingOthers: false,
  sendingForAnnexApproval: false,
  approvingAnnex: false,
  rejectingAnnex: false,
  deletingTripsAnnexure: true,
};

export const getSearchAnnexuresData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_SEARCH_ANNEXURES_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Annexure.getSearchAnnexuresDataURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: GET_SEARCH_ANNEXURES_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: GET_SEARCH_ANNEXURES_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_SEARCH_ANNEXURES_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const setTripRejectData = (data = {}) => (dispatch) => {
  dispatch({
    type: SET_TRIP_REJECT_DATA,
    payload: data,
  });
};

export const searchAnnexuresData = (
  requestData = {},
  page,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_ANNEXURES_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.searchAnnexuresDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: SEARCH_ANNEXURES_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_ANNEXURES_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ANNEXURES_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const clearSearchAnnexuresData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_ANNEXURES_DATA,
  });
};

export const getAnnexuresData = (annexureId, changeUrl) => {
  return async (dispatch) => {
    let response = {};
    let changeTheUrl = Annexure.getAnnexuresDataURL(annexureId);
    if (changeUrl == 'cost') {
      changeTheUrl = Cost.getCostAnnexuresDataURL(annexureId);
    }
    try {
      dispatch({type: GET_ANNEXURES_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: changeTheUrl,
      });
      console.log('response --GET_ANNEXURES_DATA--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_ANNEXURES_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_ANNEXURES_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ANNEXURES_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editAnnexureTripRevenue = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_ANNEXURE_TRIP_REVENUE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Annexure.editAnnexureTripRevenueURL(annexureId),
        data: requestData,
      });
      console.log('response --EDIT_ANNEXURE_TRIP_REVENUE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: EDIT_ANNEXURE_TRIP_REVENUE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_ANNEXURE_TRIP_REVENUE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_ANNEXURE_TRIP_REVENUE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editCostAnnexureTripRevenue = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_COST_ANNEXURE_TRIP_REVENUE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Cost.editCostAnnexureTripRevenueURL(annexureId),
        data: requestData,
      });
      console.log('response --EDIT_COST_ANNEXURE_TRIP_REVENUE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: EDIT_COST_ANNEXURE_TRIP_REVENUE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_COST_ANNEXURE_TRIP_REVENUE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_COST_ANNEXURE_TRIP_REVENUE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const othersHeaderEdit = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: OTHERS_HEADER_EDIT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.othersHeaderEditURL(annexureId),
        data: requestData,
      });
      console.log('response --OTHERS_HEADER_EDIT--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: OTHERS_HEADER_EDIT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: OTHERS_HEADER_EDIT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: OTHERS_HEADER_EDIT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const commentAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: COMMENT_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.commentAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --COMMENT_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: COMMENT_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: COMMENT_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: COMMENT_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const printAnnexure = (annexureId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: PRINT_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Annexure.printAnnexureURL(annexureId),
      });
      console.log('response --PRINT_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: PRINT_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: PRINT_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: PRINT_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteTripsAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_TRIPS_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.deleteTripsAnnexureURL(annexureId),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DELETE_TRIPS_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_TRIPS_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_TRIPS_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.approveAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --APPROVE_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: APPROVE_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: APPROVE_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPROVE_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const rejectAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REJECT_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.rejectAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --REJECT_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: REJECT_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: REJECT_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: REJECT_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editAnnexureDieselDate = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_ANNEXURE_DIESEL_DATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.editAnnexureDieselDateURL(annexureId),
        data: requestData,
      });
      console.log('response --EDIT_ANNEXURE_DIESEL_DATE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: EDIT_ANNEXURE_DIESEL_DATE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_ANNEXURE_DIESEL_DATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_ANNEXURE_DIESEL_DATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const annexureUploadBill = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ANNEXURE_UPLOAD_BILL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.annexureUploadBillURL(annexureId),
        data: requestData,
      });
      console.log('response --ANNEXURE_UPLOAD_BILL--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: ANNEXURE_UPLOAD_BILL_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ANNEXURE_UPLOAD_BILL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ANNEXURE_UPLOAD_BILL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createNextAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_NEXT_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.createNextAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --CREATE_NEXT_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: CREATE_NEXT_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_NEXT_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_NEXT_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const saveBillDetails = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_BILL_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.saveBillDetailsURL(annexureId),
        data: requestData,
      });
      console.log('response --SAVE_BILL_DETAILS--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SAVE_BILL_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SAVE_BILL_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_BILL_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const sendForApproval = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_FOR_APPROVAL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.sendForApprovalURL(),
        data: requestData,
      });
      console.log('response --SEND_FOR_APPROVAL--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SEND_FOR_APPROVAL_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SEND_FOR_APPROVAL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_FOR_APPROVAL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteAnnexures = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_ANNEXURES});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Annexure.deleteAnnexuresURL(),
        data: requestData,
      });
      console.log('response --DELETE_ANNEXURES--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DELETE_ANNEXURES_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_ANNEXURES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_ANNEXURES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;

  let statePresent = state && Object.keys(state).includes('annexuresData');
  const {annexures} = statePresent && state?.annexuresData;
  let updateAnnx;

  const updateTrips = {
    ...state.annexureInfo?.trips,
    ...payload['trips'],
  };
  switch (type) {
    case SEARCH_ANNEXURES_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_ANNEXURES_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
        hasError: true,
      };
    case SEARCH_ANNEXURES_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        annexuresData: {...payload},
      };
    case CLEAR_SEARCH_ANNEXURES_DATA:
      return {
        ...state,
        annexuresData: {},
      };
    case COMMENT_ANNEXURE:
      return {
        ...state,
        requestingCommentAnnex: true,
      };
    case COMMENT_ANNEXURE_FAILED:
      return {...state, requestingCommentAnnex: false};
    case COMMENT_ANNEXURE_COMPLETED:
      updateAnnx = payload.annexure_ids.reduce((acc, curr) => {
        acc[curr] = {
          basicInfo: {
            ...payload.annexures[curr].basicInfo,
          },
        };
        return acc;
      }, {});

      let annexuresDataComment = {
        ...state['annexuresData'],
        annexures: {
          ...state['annexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        requestingCommentAnnex: false,
        annexuresData: annexuresDataComment,
      };
    // return {
    //   ...state,
    //   requestingCommentAnnex: false,
    // };

    case EDIT_ANNEXURE_DIESEL_DATE_COMPLETED: {
      let updateAnnx = {
        ...state.annexureInfo,
        fuelSurchargeInfo: {
          ...state.annexureInfo.fuelSurchargeInfo,
          revised_date: payload.revised_date,
        },
      };

      return {
        ...state,
        annexureInfo: updateAnnx,
      };
    }
    case SEND_FOR_APPROVAL:
      return {
        ...state,
        sendingForAnnexApproval: true,
      };

    case SEND_FOR_APPROVAL_COMPLETED:
      updateAnnx = payload.annexure_ids.reduce((acc, curr) => {
        acc[curr] = {
          basicInfo: {
            ...payload.annexures[curr].basicInfo,
          },
        };
        return acc;
      }, {});

      let annexuresData = {
        ...state['annexuresData'],
        annexures: {
          ...state['annexuresData'].annexures,
          ...updateAnnx,
        },
      };
      return {
        ...state,
        sendingForAnnexApproval: false,
        annexuresData,
      };
    case SEND_FOR_APPROVAL_FAILED:
      return {
        ...state,
        sendingForAnnexApproval: false,
      };
    case OTHERS_HEADER_EDIT:
      return {
        ...state,
        isRequestingAnnexure: true,
        updatingOthers: true,
      };
    case OTHERS_HEADER_EDIT_COMPLETED:
      const updateAnnexuresInfo = {
        ...state.annexureInfo?.annexures,
        ...payload['annexures'],
      };
      return {
        ...state,
        annexureInfo: {
          ...state.annexureInfo,
          annexures: updateAnnexuresInfo,
        },
        isRequestingAnnexure: false,
        updatingOthers: false,
      };
    case OTHERS_HEADER_EDIT_FAILED:
      return {
        ...state,
        isRequestingAnnexure: false,
        updatingOthers: false,
      };
    case COST_OTHERS_HEADER_EDIT:
      return {
        ...state,
        isRequestingAnnexure: true,
      };
    case COST_OTHERS_HEADER_EDIT_COMPLETED:
      return {
        ...state,
        annexureInfo: {
          ...state.annexureInfo,
          annexures: payload.annexures,
        },
        isRequestingAnnexure: false,
      };
    case COST_OTHERS_HEADER_EDIT_FAILED:
      return {
        ...state,
        isRequestingAnnexure: false,
      };

    case CREATE_NEXT_ANNEXURE:
      return {
        ...state,
        requestingNextAnnex: true,
      };
    case CREATE_NEXT_ANNEXURE_COMPLETED:
      return {
        ...state,
        requestingNextAnnex: false,
      };
    case CREATE_NEXT_ANNEXURE_FAILED:
      return {
        ...state,
        requestingNextAnnex: false,
      };
    case EDIT_ANNEXURE_TRIP_REVENUE:
      return {
        ...state,
        updatingRev: true,
        isRequestingAnnexure: true,
      };
    case EDIT_ANNEXURE_TRIP_REVENUE_COMPLETED:
      return {
        ...state,
        annexureInfo: {
          ...state.annexureInfo,
          trips: updateTrips,
          total: payload.total,
        },
        updatingRev: false,
        isRequestingAnnexure: false,
      };
    case EDIT_ANNEXURE_TRIP_REVENUE_FAILED:
      return {
        ...state,
        updatingRev: false,
        isRequestingAnnexure: false,
      };
    case EDIT_COST_ANNEXURE_TRIP_REVENUE:
      return {
        ...state,
        updatingCostRev: true,
        isRequestingAnnexure: true,
      };
    case EDIT_COST_ANNEXURE_TRIP_REVENUE_COMPLETED:
      return {
        ...state,
        annexureInfo: {
          ...state.annexureInfo,
          trips: updateTrips,
          total: payload.total,
        },
        updatingCostRev: false,
        isRequestingAnnexure: false,
      };
    case EDIT_COST_ANNEXURE_TRIP_REVENUE_FAILED:
      return {
        ...state,
        updatingCostRev: false,
        isRequestingAnnexure: false,
      };
    case DELETE_COST_TRIPS_ANNEXURE:
    case DELETE_TRIPS_ANNEXURE:
      return {
        ...state,
        deletingTripsAnnexure: true,
        isRequestingAnnexure: true,
      };
    case DELETE_COST_TRIPS_ANNEXURE_COMPLETED:
    case DELETE_TRIPS_ANNEXURE_COMPLETED:
      return {
        ...state,
        annexureInfo: {
          ...state.annexureInfo,
          trip_ids: payload.trip_ids,
          trips: payload.trips,
          total: payload.total,
        },
        deletingTripsAnnexure: false,
        isRequestingAnnexure: false,
      };
    case DELETE_COST_TRIPS_ANNEXURE_FAILED:
    case DELETE_TRIPS_ANNEXURE_FAILED:
      return {
        ...state,
        deletingTripsAnnexure: false,
        isRequestingAnnexure: false,
      };
    case GET_ANNEXURES_DATA:
      return {
        ...state,
        isRequestingAnnexure: true,
      };
    case GET_ANNEXURES_DATA_COMPLETED:
      return {
        ...state,
        isRequestingAnnexure: false,
        annexureInfo: payload,
      };
    case GET_ANNEXURES_DATA_FAILED:
      return {
        ...state,
        isRequestingAnnexure: false,
      };
    case PRINT_ANNEXURE:
      return {
        ...state,
        printingAnnex: true,
      };
    case PRINT_ANNEXURE_COMPLETED:
      return {
        ...state,
        printingAnnex: false,
      };
    case PRINT_ANNEXURE_FAILED:
      return {
        ...state,
        printingAnnex: false,
      };
    case SAVE_BILL_DETAILS:
      return {
        ...state,
        savingBillDetails: true,
      };
    case SAVE_BILL_DETAILS_COMPLETED:
      updateAnnx = payload.annexure_ids.reduce((acc, curr) => {
        acc[curr] = {
          basicInfo: {
            // ...annexures[curr].basicInfo,
            // bill_number: payload.annexures[curr].basicInfo.bill_number,
            ...payload.annexures[curr].basicInfo,
          },
        };
        return acc;
      }, {});

      let annexuresBillNumberChange = {
        ...state['annexuresData'],
        annexures: {
          ...state['annexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        savingBillDetails: false,
        annexuresData: annexuresBillNumberChange,
      };
    // return {
    //   ...state,
    //   //   ...payload.trips,
    //   savingBillDetails: false,
    // };
    case SAVE_BILL_DETAILS_FAILED:
      return {
        ...state,
        savingBillDetails: false,
      };
    case DELETE_ANNEXURES:
      return {
        ...state,
        deletingAnnex: true,
      };
    case DELETE_ANNEXURES_COMPLETED:
      // delete
      updateAnnx = payload.annexure_ids.reduce((acc, curr) => {
        acc[curr] = {
          basicInfo: {
            ...payload.annexures[curr].basicInfo,
          },
        };
        return acc;
      }, {});

      let annexuresDataDelete = {
        ...state['annexuresData'],
        annexures: {
          ...state['annexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        deletingAnnex: false,
        annexuresData: annexuresDataDelete,
      };
    // return {
    //   ...state,
    //   deletingAnnex: false,
    // };
    case DELETE_ANNEXURES_FAILED:
      return {
        ...state,
        deletingAnnex: false,
      };
    case SET_TRIP_REJECT_DATA: {
      return {
        ...state,
        currentTripRejectData: payload,
      };
    }
    case ANNEXURE_UPLOAD_BILL:
      return {
        ...state,
        uploadingRevBill: true,
      };
    case ANNEXURE_UPLOAD_BILL_COMPLETED:
      return {
        ...state,
        //   ...payload.trips,
        uploadingRevBill: false,
      };
    case ANNEXURE_UPLOAD_BILL_FAILED:
      return {
        ...state,
        uploadingRevBill: false,
      };

    case GET_SEARCH_ANNEXURES_DATA_COMPLETED: {
      return {
        ...state,
        annexData: payload,
      };
    }
    // approve/reject annexure for revenue starts
    case APPROVE_ANNEXURE:
      return {
        ...state,
        approvingAnnex: true,
      };
    case APPROVE_ANNEXURE_COMPLETED:
      return {
        ...state,
        approvingAnnex: false,
      };
    case APPROVE_ANNEXURE_FAILED:
      return {
        ...state,
        approvingAnnex: false,
      };
    case REJECT_ANNEXURE:
      return {
        ...state,
        rejectingAnnex: true,
      };
    case REJECT_ANNEXURE_COMPLETED:
      return {
        ...state,
        rejectingAnnex: false,
      };
    case REJECT_ANNEXURE_FAILED:
      return {
        ...state,
        rejectingAnnex: false,
      };
    // approve/reject annexure for revenue ends

    // case RESET_PAGE:
    //   return {...state, resetPage: !state.resetPage};
    default: {
      if (payload.trips) {
        return {...state, ...payload.trips};
      }
      return state;
    }
  }
};

const setInitialSearchVehicleIds = (state, payload = {}) => {
  const {search_vehicle_ids, vehicle_ids} = payload;
  if (search_vehicle_ids) {
    return [...new Set([...state, ...search_vehicle_ids])];
  }
  if (vehicle_ids) {
    return [...new Set([...state, ...vehicle_ids])];
  } else return state;
};
export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitialSearchVehicleIds(state, payload);
    }
  }
};

import {merge} from 'lodash';

const setInitialDieselRecordsData = (state, payload = {}) => {
  const {diesel_records_detail, diesel_advance_records} = payload;
  //   console.log()
  console.log('state---DieselRecordsData', state, payload);
  if (diesel_records_detail) {
    const oldRecords = {...state};
    const newRecords = {...diesel_records_detail};
    const updatedState = merge(oldRecords, newRecords);
    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  console.log('state', action);
  switch (type) {
    default: {
      return setInitialDieselRecordsData(state, payload);
    }
  }
};
